import React from "react";

const Success = () => {
    return (
        <div>
            <h1>Success - Thank you for your email!</h1>
        </div>
    );
};

export default Success;
